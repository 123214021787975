// ** React & Mui
import { useEffect, useState } from 'react';
import { Stack, Typography, Box, CardMedia, useTheme, Button, useMediaQuery, Avatar } from '@mui/material';

// ** Hooks
import { useSession } from '@/context/session';
import { useModalsActions } from '@/context/modals';
import { useEthersSigner } from '@/hooks/useEthersAdapters';
import { ConnectedWallet, useWallets } from '@privy-io/react-auth';

// ** Components
import BlankCard from '../shared/BlankCard';

// ** Utils & Types
import { NotConnectedCase } from '../modals/not-connected';
import axios from 'axios';
import { dwebGateway, getIPFSGateway } from '@/utils/constants/api';
import { MintableReturn, OnchainSalesStrategies } from '@zoralabs/protocol-sdk/dist/mint/types';
import { ZORA_ID } from '@/utils/constants/networks';
import { ProfileItem } from '@/types/custom';
import Link from 'next/link';
import { getAvatarUrl } from '@/utils';
import { check1155Ownership } from '@/utils/helpers';
const getGatewayUrl = (cid: string) => {
  if (cid.startsWith('Q')) {
    return getIPFSGateway(cid);
  } else if (cid.startsWith('baf')) {
    return dwebGateway(cid);
  }
  return getIPFSGateway(cid);
};
interface ZoraItemCardProps {
  token: {
    zoraData: MintableReturn | undefined;
    backendData: {
      tokenId: string;
      collectionAddress: `0x${string}`;
    };
  };
  collectors?: ProfileItem[];
}
export const ZoraFeedItemCard = ({
  token,
  collectors
}: ZoraItemCardProps) => {
  const [text, setText] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contractAddress, setContractAddress] = useState<`0x${string}` | null>(null);
  const [isCollected, setIsCollected] = useState(false);
  const [isAvailableSupply, setIsAvailableSupply] = useState(false);
  const [collectMintType, setCollectMintType] = useState<'721' | '1155'>();
  const [salesConfig, setSalesConfig] = useState<OnchainSalesStrategies>();
  const [referredProfile, setReferredProfile] = useState<ProfileItem>();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    user,
    notLoggedIn,
    connectedNotLogged,
    isFarcasterUser
  } = useSession();
  const {
    open: openModal
  } = useModalsActions();
  const {
    zoraData,
    backendData
  } = token;
  const signer = useEthersSigner();
  const {
    wallets
  } = useWallets();
  useEffect(() => {
    getContentPost();
    return cleanState;
  }, [token]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const cleanState = () => {
    setText(null);
    setImage(null);
    setContractAddress(null);
    setIsCollected(false);
    setSalesConfig(undefined);
    setReferredProfile(undefined);
  };
  const getContentPost = async () => {
    try {
      setIsLoading(true);
      if (!zoraData) throw new Error('No zora data');
      const {
        token
      } = zoraData;
      const {
        tokenId
      } = backendData;
      setContractAddress(token.contract?.address);
      setCollectMintType(token?.mintType === '721' ? '721' : '1155');
      if (signer && user.address) {
        const isOwned = await check1155Ownership(signer, token.contract.address, Number(tokenId), user.address);
        setIsCollected(isOwned);
      }
      const isAvailable = Number(token.maxSupply) > Number(token.totalMinted);
      setIsAvailableSupply(isAvailable);
      if ('salesConfig' in token && token.salesConfig) {
        setSalesConfig(token.salesConfig);
      }
      if (collectors && collectors.length > 0) {
        setReferredProfile(collectors[0]);
      }
      let metadata;
      if (token.tokenURI.startsWith('data:application/json;base64,')) {
        const base64Data = token.tokenURI.replace('data:application/json;base64,', '');
        const decodedData = Buffer.from(base64Data, 'base64').toString('utf-8');
        metadata = JSON.parse(decodedData);
      } else {
        const tokenCID = token.tokenURI.split('//').pop();
        if (!tokenCID) throw new Error('No tokenCID found');
        const metadataResponse = await axios.get(dwebGateway(tokenCID));
        metadata = metadataResponse.data;
      }
      setText(metadata?.name);
      const contentImgCID = metadata?.image.split('//').pop();
      setImage(contentImgCID ? getGatewayUrl(contentImgCID) : null);
    } catch (error) {
      console.log('Error getting token content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkZoraNetwork = async (wallet: ConnectedWallet) => {
    if (wallet.chainId === ZORA_ID.toString()) return true;else {
      try {
        await wallet.switchChain(ZORA_ID);
        return true;
      } catch (error) {
        console.log('Error switching to zora network', error);
        return false;
      }
    }
  };
  const handleSubmitCollect = async () => {
    try {
      if (notLoggedIn) {
        openModal('notConnected', {
          modalCase: NotConnectedCase.NotConnected
        });
        return;
      }
      if (connectedNotLogged) {
        openModal('notConnected', {
          modalCase: NotConnectedCase.PendingLogIn
        });
        return;
      }
      if (!contractAddress || !collectMintType || isFarcasterUser) return;
      const wallet = wallets.length > 0 ? wallets[0] : null;
      if (!wallet) return;
      const isZoraNetwork = await checkZoraNetwork(wallet);
      if (!isZoraNetwork) return;
      openModal('zoraCollect', {
        mintType: collectMintType,
        tokenAddress: contractAddress,
        tokenId: backendData.tokenId,
        tokenName: text || '',
        salesConfig,
        referral: referredProfile?.ownedBy.address,
        onSuccess: () => {
          setIsCollected(true);
        }
      });
    } catch (error) {
      console.log('Error minting zora token', error);
    }
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const PostMedia = () => {
    const mediaStyles = {
      height: '16em',
      width: '100%',
      borderRadius: '0.5em'
    };
    if (image) {
      return <CardMedia component="img" sx={mediaStyles} image={image} alt="lens image" />;
    } else {
      return <Box sx={{
        height: '16em'
      }}></Box>;
    }
  };
  const CollectedBy = () => {
    return <Stack height={isXsScreen ? undefined : '32em'} direction="row" alignItems="center" spacing={1} flexWrap="nowrap" overflow="hidden" sx={{
      marginBottom: '0.5rem'
    }} data-sentry-element="Stack" data-sentry-component="CollectedBy" data-sentry-source-file="ZoraItem.tsx">
        <Typography variant="body1" fontWeight="bold" noWrap data-sentry-element="Typography" data-sentry-source-file="ZoraItem.tsx">
          Minted by
        </Typography>
        <Link href={`/profile/${referredProfile?.id}`} style={{
        textDecoration: 'none',
        color: 'inherit'
      }} passHref data-sentry-element="Link" data-sentry-source-file="ZoraItem.tsx">
          <Stack direction="row" alignItems="center" spacing={1} sx={{
          '&:hover': {
            cursor: 'pointer',
            '& .avatar': {
              filter: 'brightness(0.5)',
              transition: '0.2s'
            },
            '& .name': {
              color: theme.palette.primary.main,
              transition: '0.2s'
            }
          }
        }} data-sentry-element="Stack" data-sentry-source-file="ZoraItem.tsx">
            <Avatar className="avatar" src={getAvatarUrl(referredProfile)} alt={referredProfile?.handle?.localName} sx={{
            width: 30,
            height: 30
          }} data-sentry-element="Avatar" data-sentry-source-file="ZoraItem.tsx" />
            <Typography variant="body1" fontWeight={'bold'} className="name" data-sentry-element="Typography" data-sentry-source-file="ZoraItem.tsx">
              {referredProfile?.handle?.localName}
            </Typography>
          </Stack>
        </Link>
      </Stack>;
  };
  return <BlankCard className="hoverCard" data-sentry-element="BlankCard" data-sentry-component="ZoraFeedItemCard" data-sentry-source-file="ZoraItem.tsx">
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} p={2} sx={{
      minHeight: '24rem',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        transition: 'background-color 0.3s'
      }
    }} data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
        <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 2
      }} data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
          <Box display="flex" justifyContent="center" data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
            {referredProfile ? <CollectedBy /> : null}
          </Box>
          <Box alignContent="center" position="relative" data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
            <PostMedia data-sentry-element="PostMedia" data-sentry-source-file="ZoraItem.tsx" />
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} mt={1} data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
          <Typography variant="h5" textAlign="center" sx={{
          '&.MuiTypography-root': {
            lineHeight: {
              xs: 1.2
            }
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          whiteSpace: 'nowrap'
        }} data-sentry-element="Typography" data-sentry-source-file="ZoraItem.tsx">
            {text}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} data-sentry-element="Box" data-sentry-source-file="ZoraItem.tsx">
          <Button size={isSmScreen ? 'medium' : 'small'} color="secondary" variant="contained" sx={{
          textWrap: 'nowrap'
        }} disabled={isLoading || isCollected || !isAvailableSupply || isFarcasterUser} onClick={handleSubmitCollect} data-sentry-element="Button" data-sentry-source-file="ZoraItem.tsx">
            {isCollected ? 'Collected' : 'Collect'}
          </Button>
        </Box>
      </Box>
    </BlankCard>;
};