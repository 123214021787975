import { PublicationMetadataMediaFragment } from '@lens-protocol/client'

export const getAttachmentsData = (
  attachments?: PublicationMetadataMediaFragment[] | null
): {
  type: 'Audio' | 'Video' | 'Image'
  uri?: string
  coverUri?: string
  artist?: string
}[] => {
  if (!attachments) return []

  const attachmentsData = attachments.map((attachment) => {
    switch (attachment.__typename) {
      case 'PublicationMetadataMediaImage':
        return {
          type: 'Image',
          uri: attachment.image.optimized?.uri,
        }
      case 'PublicationMetadataMediaVideo':
        return {
          coverUri: attachment.cover?.optimized?.uri,
          type: 'Video',
          uri: attachment.video.optimized?.uri,
        }
      case 'PublicationMetadataMediaAudio':
        return {
          artist: attachment.artist || undefined,
          coverUri: attachment.cover?.optimized?.uri,
          type: 'Audio',
          uri: attachment.audio.optimized?.uri,
        }
      default:
        return null
    }
  })

  return attachmentsData.filter((attachment) => attachment !== null) as {
    type: 'Audio' | 'Video' | 'Image'
    uri?: string
    coverUri?: string
    artist?: string
  }[]
}
