import { PublicationMetadataFragment } from '@lens-protocol/client'
import { getAttachmentsData } from './getAttachmentsData'

type MetadataAsset = {
  artist?: string
  cover?: string
  license?: string
  title?: string
  type: 'Audio' | 'Video' | 'Image'
  uri?: string
}

export const getPublicationData = (
  metadata: PublicationMetadataFragment
): {
  asset?: MetadataAsset
  attachments?: {
    type: 'Audio' | 'Video' | 'Image'
    artist?: string
    coverUri?: string
    uri?: string
  }[]
  content: string
} | null => {
  let content = ''

  if (metadata.__typename !== 'EventMetadataV3') {
    content = metadata.content
  }

  switch (metadata.__typename) {
    case 'ArticleMetadataV3':
      return {
        attachments: getAttachmentsData(metadata.attachments),
        content,
      }
    case 'TextOnlyMetadataV3':
    case 'LinkMetadataV3':
      return {
        content,
      }
    case 'ImageMetadataV3':
      return {
        asset: {
          type: 'Image',
          uri: metadata.asset.image.optimized?.uri,
        },
        attachments: getAttachmentsData(metadata.attachments),
        content,
      }
    case 'AudioMetadataV3':
      const audioAttachments = getAttachmentsData(metadata.attachments)
      return {
        asset: {
          artist: metadata.asset.artist || audioAttachments?.artist,
          cover:
            metadata.asset.cover?.optimized?.uri ||
            audioAttachments?.coverUri ||
            undefined,
          license: metadata.asset.license || undefined,
          title: metadata.title,
          type: 'Audio',
          uri: metadata.asset.audio.optimized?.uri || audioAttachments?.uri,
        },
        content,
      }
    case 'VideoMetadataV3':
      const videoAttachments = getAttachmentsData(metadata.attachments)
      return {
        asset: {
          cover:
            metadata.asset.cover?.optimized?.uri || videoAttachments?.coverUri,
          license: metadata.asset.license || undefined,
          type: 'Video',
          uri: metadata.asset.video.optimized?.uri || videoAttachments?.uri,
        },
        content,
      }
    case 'MintMetadataV3':
    case 'LiveStreamMetadataV3':
    case 'CheckingInMetadataV3':
      return {
        attachments: getAttachmentsData(metadata.attachments),
        content,
      }
    default:
      return null
  }
}
