// ** React, Next & Mui
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Stack, Avatar, Box, CardMedia, Chip, IconButton, Tooltip, useTheme, Typography, Button, useMediaQuery } from '@mui/material';
import { IconAward, IconMessage2, IconRepeat, IconThumbUp } from '@tabler/icons-react';
import { formatDistance } from 'date-fns';

// ** Context
import { useLens } from '../../context/lens';
import { useModalsActions } from '@/context/modals';
import { useSession } from '@/context/session';

// ** Components
import BlankCard from '../shared/BlankCard';
import ReactPlayer from 'react-player';
import { ChildPostDetail } from '../content/ChildPostDetail';
import { TruncateText } from '../shared/TruncateText';

// ** Utils
import { AnyPublicationFragment, isMirrorPublication } from '@lens-protocol/client';
import { getAvatarUrl } from '../../utils';
import { NotConnectedCase } from '../modals/not-connected';
import { CollectCriteria } from '@/utils/constants/types';
import { getPublicationData } from '@/helpers/getPublicationData';
interface Props {
  post: AnyPublicationFragment;
  category?: string;
}
export const FeedPostCard = ({
  post,
  category
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [collects, setCollects] = useState(0);
  const [isCollected, setIsCollected] = useState(false);
  const [collectCriteria, setCollectCriteria] = useState<CollectCriteria | null>();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isDarkMode = theme.palette.mode === 'dark';
  const {
    like,
    unlike,
    fetchCollectCriteria
  } = useLens();
  const {
    open
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged,
    isFarcasterUser
  } = useSession();
  const {
    by
  } = post;
  const isMirror = isMirrorPublication(post);
  const targetPublication = isMirror ? post.mirrorOn : post;
  const {
    metadata,
    profilesMentioned
  } = targetPublication;
  const filteredContent = getPublicationData(metadata)?.content || '';
  const filteredAttachments = getPublicationData(metadata)?.attachments || [];
  const filteredAsset = getPublicationData(metadata)?.asset;
  const showQuote = targetPublication.__typename === 'Quote';
  const mirroredBy = isMirror ? {
    name: post.by.metadata?.displayName || `@${post.by.handle?.localName}`,
    avatar: getAvatarUrl(post.by)
  } : null;
  const isMomokaPost = Boolean(post.momoka);
  useEffect(() => {
    getContentPost();
    return cleanState;
  }, [post]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const cleanState = () => {
    setIsLoading(true);
    setLikes(0);
    setIsLiked(false);
    setCollects(0);
    setIsCollected(false);
    setCollectCriteria(null);
  };
  const getContentPost = async () => {
    try {
      setIsLoading(true);
      if (post.__typename === 'Post') {
        const {
          stats,
          operations,
          openActionModules
        } = post;
        setLikes(stats?.upvotes);
        setCollects(stats?.collects);
        setIsCollected(operations.hasCollected.value);
        if (operations.hasUpvoted) setIsLiked(true);
        if (openActionModules.length > 0) {
          const collectData = await fetchCollectCriteria(post.id);
          setCollectCriteria(collectData);
        }
      }
    } catch (error) {
      console.log('Error getting post content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleLikeClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    if (!isLiked) {
      await like(post?.id);
      setIsLiked(true);
      setLikes(prev => prev + 1);
    } else {
      await unlike(post?.id);
      setIsLiked(false);
      setLikes(prev => prev - 1);
    }
  };
  const handleCommentClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('comment', {
      publicationId: post.id,
      isMomokaPub: isMomokaPost
    });
  };
  const handleCollectClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('collect', {
      publicationId: post.id as string,
      onSuccess: () => {
        setIsCollected(true);
        setCollects(prev => prev + 1);
      }
    });
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const postDetailPath = post ? `/profile/post/${post.id}` : '/';
  const userProfilePath = by ? `/profile/${by.id}` : '/';
  const timeAgo = useMemo(() => {
    const createdAt = new Date(post.createdAt);
    const now = new Date();
    return formatDistance(createdAt, now);
  }, [post.createdAt]);
  const UserDetail = () => <Box display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems="flex-end" width={'100%'} py={1} data-sentry-element="Box" data-sentry-component="UserDetail" data-sentry-source-file="PostCard.tsx">
      <Link href={userProfilePath} data-sentry-element="Link" data-sentry-source-file="PostCard.tsx">
        <Stack gap={0.5} sx={{
        '&:hover': {
          cursor: 'pointer',
          '& .avatar': {
            filter: 'brightness(0.5)',
            transition: '0.5s'
          },
          '& .handle': {
            color: 'text.secondary',
            transition: '0.5s',
            cursor: 'pointer'
          }
        }
      }} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
          <Avatar alt={by?.handle?.localName} src={getAvatarUrl(by)} className="avatar" data-sentry-element="Avatar" data-sentry-source-file="PostCard.tsx" />
          <Chip label={by?.metadata?.displayName || `@${by?.handle?.localName}`} size="small" sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }} className="handle" data-sentry-element="Chip" data-sentry-source-file="PostCard.tsx" />
        </Stack>
      </Link>

      {category ? <Chip label={category} size="small" sx={{
      color: theme.palette.grey[900],
      backgroundColor: isDarkMode ? '#d4b715' : '#faeb96',
      fontWeight: 'normal',
      overflow: 'hidden'
    }} /> : null}
    </Box>;
  const CollectButton = () => {
    const canCollect = collectCriteria?.canCollect === 'YES';
    if (isFarcasterUser) return null;
    if (isCollected) {
      return <Button disabled={isCollected} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collected
        </Button>;
    } else if (canCollect) {
      return <Button disabled={!canCollect} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collect
        </Button>;
    }
    return null;
  };
  return <BlankCard className="hoverCard" data-sentry-element="BlankCard" data-sentry-component="FeedPostCard" data-sentry-source-file="PostCard.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'} py={0} height={isXsScreen ? undefined : '32em'} sx={{
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        transition: 'background-color 0.3s'
      }
    }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
        <Box px={1} width="100%" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
          <UserDetail data-sentry-element="UserDetail" data-sentry-source-file="PostCard.tsx" />
        </Box>
        {filteredAsset?.type === 'Image' ? <Link href={postDetailPath}>
            <CardMedia component="img" image={filteredAsset.uri} alt="lens image" sx={{
          width: '100%',
          height: isXsScreen ? '16em' : '12em',
          objectFit: 'cover',
          borderRadius: '6px'
        }} />
          </Link> : null}

        {filteredAsset?.type === 'Video' ? <Box sx={{
        position: 'relative',
        paddingTop: '10em',
        height: '0',
        overflow: 'hidden'
      }}>
            <ReactPlayer url={filteredAsset.uri} controls={true} width="100%" height="100%" style={{
          position: 'absolute',
          top: '0',
          left: '0'
        }} />
          </Box> : null}

        {mirroredBy ? <Box display="flex" alignItems="center" gap={0.5}>
            <IconRepeat size="18" />
            <Chip avatar={<Avatar alt={mirroredBy.name} src={mirroredBy.avatar} />} component={'div'} label={mirroredBy.name} size="small" sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }} />
            <Typography>mirrored</Typography>
          </Box> : <Box px={1} width="100%">
            <Link href={postDetailPath} style={{
          textDecoration: 'none',
          color: 'unset'
        }}>
              {filteredAsset?.type === 'Audio' ? <Box sx={{
            paddingTop: '1.5rem',
            height: '3.5rem'
          }}>
                  <ReactPlayer url={filteredAsset.uri} controls={true} width="100%" height="100%" />
                </Box> : null}
              <Box my={{
            xs: 3,
            sm: 0.5
          }}>
                <TruncateText text={filteredContent} postId={post.id} mentions={profilesMentioned} lines={filteredAsset?.type === 'Image' || filteredAsset?.type === 'Video' ? 4 : filteredAsset?.type === 'Audio' ? 5 : 10} textLength={80} cleanNewLines />
              </Box>
            </Link>
          </Box>}

        {isMirror ? <ChildPostDetail post={post.mirrorOn as AnyPublicationFragment} /> : showQuote ? <ChildPostDetail post={targetPublication.quoteOn as AnyPublicationFragment} /> : null}

        <CollectButton data-sentry-element="CollectButton" data-sentry-source-file="PostCard.tsx" />

        <Box width="100%" mt="auto" mb={1} px={1} display="flex" flexDirection="column" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
          <Box display="flex" justifyContent="flex-end" width="100%" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
            <Typography variant="caption" color="textSecondary" data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">
              {timeAgo}
            </Typography>
          </Box>
          <Stack direction="row" gap={3} alignItems="center" justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
            <Stack direction="row" gap={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
              <Tooltip title="Likes" placement="top" data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
                <IconButton onClick={handleLikeClick} color={isLiked ? 'primary' : 'inherit'} data-sentry-element="IconButton" data-sentry-source-file="PostCard.tsx">
                  <IconThumbUp size="28" data-sentry-element="IconThumbUp" data-sentry-source-file="PostCard.tsx" />{' '}
                </IconButton>
              </Tooltip>
              {likes.toLocaleString()}
            </Stack>
            {post.__typename !== 'Mirror' && <Stack direction="row" gap={0.5} alignItems="center">
                <Tooltip title="Comments" placement="top">
                  <IconButton color="inherit" onClick={handleCommentClick}>
                    <IconMessage2 size="28" />
                  </IconButton>
                </Tooltip>
                {post.stats?.comments.toLocaleString()}
              </Stack>}
            {!isMomokaPost && <Stack direction="row" gap={0.5} alignItems="center">
                <Tooltip title="Collects" placement="top">
                  <IconButton color={isCollected ? 'primary' : 'inherit'} onClick={handleCollectClick}>
                    <IconAward size="28" />
                  </IconButton>
                </Tooltip>
                {collects.toLocaleString()}
              </Stack>}
          </Stack>
        </Box>
      </Box>
    </BlankCard>;
};