// ** React, Next & Mui
import { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Box, CardMedia, useTheme, Button, IconButton, Slider, useMediaQuery, Avatar } from '@mui/material';
import Link from 'next/link';
import { IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react';

// ** Components
import BlankCard from '../shared/BlankCard';

// ** Context
import { arweaveGateWay, getAvatarUrl } from '@/utils';
import { NotConnectedCase } from '../modals/not-connected';
import { useModalsActions } from '@/context/modals';
import { useSession } from '@/context/session';
import { PodMetadataType, ProfileItem } from '@/types/custom';
import { TruncateText } from '../shared/TruncateText';
type PodsItemCardProps = {
  podId: string;
  tokenId: string;
  metadataURI: string;
  collectors: ProfileItem[];
  metadata: PodMetadataType;
};
export const PodsFeedItemCard = (pod: PodsItemCardProps) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    open: openModal
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged,
    isFarcasterUser
  } = useSession();
  const {
    metadata
  } = pod;
  const [title, setTitle] = useState<string | null>(null);
  const [subTitle, setSubTitle] = useState<string | null>(null);
  const [collection, setCollection] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [imgError, setImgError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isCollected, setIsCollected] = useState(false);
  const [contractAddress, setContractAddress] = useState<`0x${string}`>();
  const [tokenId, setTokenId] = useState<string>('');
  const [referredProfile, setReferredProfile] = useState<ProfileItem>();
  useEffect(() => {
    getContentPod();
  }, [pod]);
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('timeupdate', () => {
        setCurrentTime(audio.currentTime);
      });
    }
    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', () => {
          setCurrentTime(audio.currentTime);
        });
      }
    };
  }, []);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const getContentPod = async () => {
    if (!metadata) return;
    try {
      setIsLoading(true);
      if (!metadata.episodeTitle) return;
      setTitle(metadata.episodeTitle);
      if (!metadata.podcast) return;
      setSubTitle(metadata.podcast);
      if (metadata.collection) {
        setCollection(metadata.collection);
      }
      if (!metadata.artwork?.uri) return;
      const imageUrl = arweaveGateWay(metadata.artwork?.uri);
      setContractAddress(pod?.podId as `0x${string}`);
      setTokenId(pod?.tokenId);
      if (pod?.collectors.length > 0) {
        setReferredProfile(pod.collectors[0]);
      }
      setImage(imageUrl);
    } catch (error) {
      console.log('Error getting pod content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmitCollect = async () => {
    try {
      if (notLoggedIn || connectedNotLogged) {
        openModal('notConnected', {
          modalCase: NotConnectedCase.NotConnected
        });
        return;
      }
      if (!contractAddress || isFarcasterUser) return;
      openModal('podCollect', {
        podAddress: contractAddress,
        tokenId: tokenId,
        mintReferral: referredProfile?.ownedBy.address,
        onSuccess: () => {
          setIsCollected(true);
        }
      });
    } catch (error) {
      console.log('Error minting pod', error);
    }
  };
  const handlePlayPause = async () => {
    if (!metadata?.primaryMedia?.uri) return;
    try {
      if (audioRef.current) {
        if (!audioRef.current.src) {
          const audioUrl = arweaveGateWay(metadata.primaryMedia.uri);
          audioRef.current.src = audioUrl;
          await audioRef.current.load();
        }
        if (isPlaying) {
          await audioRef.current.pause();
        } else {
          const playPromise = audioRef.current.play();
          if (playPromise !== undefined) {
            try {
              await playPromise;
            } catch (error) {
              console.error('Error playing audio:', error);
              return;
            }
          }
        }
        setIsPlaying(!isPlaying);
      }
    } catch (error) {
      console.error('Error handling play/pause:', error);
      setIsPlaying(false);
    }
  };
  const handleSeek = (event: Event, newValue: number | number[]) => {
    const seekTime = newValue as number;
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
    }
  };
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const PostMedia = () => {
    const mediaStyles = {
      height: {
        xs: '16em',
        sm: '14em'
      },
      width: '100%',
      borderRadius: '0.5em'
    };
    if (image && !imgError) {
      const handleImageSrc = () => {
        return `/api/proxy/fetch-images?url=${encodeURIComponent(image)}`;
      };
      return <Box sx={mediaStyles}>
          <CardMedia component="img" sx={{
          height: '100%',
          width: '100%',
          objectFit: 'fill',
          borderRadius: '0.5em'
        }} image={handleImageSrc()} alt="lens image" loading="lazy" onError={() => {
          setImgError(true);
        }} />
        </Box>;
    } else {
      return <Box sx={{
        height: '14em'
      }}></Box>;
    }
  };
  const CollectedBy = () => {
    return <Stack height={isXsScreen ? undefined : '32em'} direction="row" alignItems="center" spacing={1} flexWrap="nowrap" overflow="hidden" data-sentry-element="Stack" data-sentry-component="CollectedBy" data-sentry-source-file="PodsItem.tsx">
        <Typography variant="body1" fontWeight="bold" noWrap data-sentry-element="Typography" data-sentry-source-file="PodsItem.tsx">
          Collected by
        </Typography>
        <Link href={`/profile/${referredProfile?.id}`} style={{
        textDecoration: 'none',
        color: 'inherit'
      }} passHref data-sentry-element="Link" data-sentry-source-file="PodsItem.tsx">
          <Stack direction="row" alignItems="center" spacing={1} sx={{
          '&:hover': {
            cursor: 'pointer',
            '& .avatar': {
              filter: 'brightness(0.5)',
              transition: '0.2s'
            },
            '& .name': {
              color: theme.palette.primary.main,
              transition: '0.2s'
            }
          }
        }} data-sentry-element="Stack" data-sentry-source-file="PodsItem.tsx">
            <Avatar className="avatar" src={getAvatarUrl(referredProfile)} alt={referredProfile?.handle?.localName} sx={{
            width: 30,
            height: 30
          }} data-sentry-element="Avatar" data-sentry-source-file="PodsItem.tsx" />
            <Typography variant="body1" fontWeight={'bold'} className="name" textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} maxWidth={{
            xs: '100%',
            sm: '7rem'
          }} data-sentry-element="Typography" data-sentry-source-file="PodsItem.tsx">
              {referredProfile?.handle?.localName}
            </Typography>
          </Stack>
        </Link>
      </Stack>;
  };
  return <BlankCard className="hoverCard" data-sentry-element="BlankCard" data-sentry-component="PodsFeedItemCard" data-sentry-source-file="PodsItem.tsx">
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} p={2} sx={{
      minHeight: '24rem',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        transition: 'background-color 0.3s'
      }
    }} data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
        <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 2
      }} data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
          <Box display="flex" justifyContent="center" data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
            {referredProfile ? <CollectedBy /> : null}
          </Box>
          <Box alignContent="center" sx={{
          mt: 1,
          mb: 0.25
        }} data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
            <PostMedia data-sentry-element="PostMedia" data-sentry-source-file="PodsItem.tsx" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems={'center'} justifyContent="center" gap={{
        xs: 1,
        sm: 0.25
      }} data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
          <Stack gap={{
          xs: 0.5,
          sm: 0
        }} alignItems="center" justifyContent="center" sx={{
          px: '1rem'
        }} data-sentry-element="Stack" data-sentry-source-file="PodsItem.tsx">
            <TruncateText text={title} cleanNewLines lines={1} typographyProps={{
            variant: 'h6'
          }} sx={{
            fontWeight: 500,
            textAlign: 'center',
            '& p': {
              marginTop: 0,
              marginBottom: 0
            }
          }} data-sentry-element="TruncateText" data-sentry-source-file="PodsItem.tsx" />
            <Stack direction="row" alignItems="center" gap={0.5} data-sentry-element="Stack" data-sentry-source-file="PodsItem.tsx">
              <TruncateText text={subTitle} cleanNewLines lines={1} typographyProps={{
              variant: 'caption'
            }} sx={{
              fontWeight: 350,
              fontSize: 13,
              '& p': {
                marginTop: 0,
                marginBottom: 0
              },
              maxWidth: '50%'
            }} data-sentry-element="TruncateText" data-sentry-source-file="PodsItem.tsx" />
              {collection && <>
                  <Typography variant="caption" fontSize={15} fontWeight={200}>
                    |
                  </Typography>
                  <TruncateText text={collection} cleanNewLines lines={1} typographyProps={{
                variant: 'caption'
              }} sx={{
                fontWeight: 350,
                fontSize: 13,
                '& p': {
                  marginTop: 0,
                  marginBottom: 0
                },
                maxWidth: '50%'
              }} />
                </>}
            </Stack>
          </Stack>

          <Stack direction="row" gap={2} alignItems="center" sx={{
          width: '60%'
        }} data-sentry-element="Stack" data-sentry-source-file="PodsItem.tsx">
            <IconButton onClick={handlePlayPause} sx={{
            padding: {
              sm: '0.25rem',
              md: '0.3rem'
            },
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
              transition: 'background-color 0.3s ease, color 0.3s ease',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.grey[300]
            }
          }} data-sentry-element="IconButton" data-sentry-source-file="PodsItem.tsx">
              {isPlaying ? <IconPlayerPause /> : <IconPlayerPlay />}
            </IconButton>
            <Box display={'flex'} alignItems={'center'} sx={{
            width: '100%',
            padding: '0 0.5rem'
          }} data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
              <Slider value={currentTime} min={0} valueLabelFormat={formatTime(currentTime)} max={audioRef.current?.duration || 0} onChange={handleSeek} valueLabelDisplay="auto" sx={{
              width: '100%'
            }} data-sentry-element="Slider" data-sentry-source-file="PodsItem.tsx" />
            </Box>
            <Typography variant="caption" color="GrayText" data-sentry-element="Typography" data-sentry-source-file="PodsItem.tsx">
              {formatTime(audioRef.current?.duration || 0)}
            </Typography>
          </Stack>
          <Box data-sentry-element="Box" data-sentry-source-file="PodsItem.tsx">
            <Button size={isSmScreen ? 'medium' : 'small'} color="secondary" variant="contained" fullWidth sx={{
            textWrap: 'nowrap'
          }} disabled={isLoading || isFarcasterUser} onClick={handleSubmitCollect} data-sentry-element="Button" data-sentry-source-file="PodsItem.tsx">
              Collect
            </Button>
          </Box>
        </Box>
        <audio ref={audioRef} onError={e => {
        console.error('Audio error:', e);
        setIsPlaying(false);
      }} src={metadata?.primaryMedia?.uri ? arweaveGateWay(metadata.primaryMedia.uri) : ''} />
      </Box>
    </BlankCard>;
};