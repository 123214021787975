// ** Next & MUI
import Link from 'next/link';
import { Box, CardMedia, Typography, useTheme, useMediaQuery, Stack, Avatar } from '@mui/material';

// ** Components
import ReactPlayer from 'react-player';
import { TruncateText } from '../shared/TruncateText';

// ** Utils & Types
import { AnyPublicationFragment, isMirrorPublication } from '@lens-protocol/client';
import { getPublicationData } from '@/helpers/getPublicationData';
import { getAvatarUrl } from '@/utils';
type Props = {
  post: AnyPublicationFragment;
};
export const ChildPostDetail = ({
  post
}: Props) => {
  const targetPublication = isMirrorPublication(post) ? post.mirrorOn : post;
  const {
    metadata,
    profilesMentioned
  } = targetPublication;
  const filteredContent = getPublicationData(metadata)?.content || '';
  const filteredAttachments = getPublicationData(metadata)?.attachments || [];
  const filteredAsset = getPublicationData(metadata)?.asset;
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';
  const postDetailPath = `/content/${post.id}`;
  return <Link href={postDetailPath} style={{
    textDecoration: 'none',
    color: 'inherit'
  }} data-sentry-element="Link" data-sentry-component="ChildPostDetail" data-sentry-source-file="ChildPostDetail.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} border={`2px dotted ${isDarkMode ? theme.palette.grey[200] : theme.palette.divider}`} sx={{
      py: 1,
      px: 2,
      my: 1,
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
        borderColor: isDarkMode ? theme.palette.grey[300] : theme.palette.divider,
        backgroundColor: isDarkMode ? 'transparent' : theme.palette.background.paper,
        transition: '0.2s'
      }
    }} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
        <Box p={1} mt={1} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
          <Link href={`/profile/${post.by.id}`} style={{
          textDecoration: 'none',
          color: 'inherit'
        }} passHref data-sentry-element="Link" data-sentry-source-file="ChildPostDetail.tsx">
            <Stack direction="row" alignItems="center" spacing={1} sx={{
            '&:hover': {
              cursor: 'pointer',
              '& .avatar': {
                filter: 'brightness(0.5)',
                transition: '0.2s'
              },
              '& .name': {
                color: theme.palette.primary.main,
                transition: '0.2s'
              },
              '& .handle': {
                color: theme.palette.grey[400],
                transition: '0.2s'
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="ChildPostDetail.tsx">
              <Avatar className="avatar" src={getAvatarUrl(post.by)} data-sentry-element="Avatar" data-sentry-source-file="ChildPostDetail.tsx" />
              <Typography variant="h6" className="name" data-sentry-element="Typography" data-sentry-source-file="ChildPostDetail.tsx">
                {post.by.metadata?.displayName}
              </Typography>
              <Typography variant="caption" className="handle" data-sentry-element="Typography" data-sentry-source-file="ChildPostDetail.tsx">
                {`@${post.by.handle?.localName}`}
              </Typography>
            </Stack>
          </Link>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
          <Box my={1} width={'100%'} data-sentry-element="Box" data-sentry-source-file="ChildPostDetail.tsx">
            <TruncateText text={filteredContent} mentions={profilesMentioned} textLength={250} cleanNewLines data-sentry-element="TruncateText" data-sentry-source-file="ChildPostDetail.tsx" />
          </Box>
          {filteredAsset?.type === 'Image' ? <Link href={postDetailPath}>
              <Box display={'flex'} justifyContent={'center'} sx={{
            width: '100%'
          }}>
                <CardMedia component="img" image={filteredAsset.uri} alt="lens image" width={'100%'} height={isSmScreen ? 300 : 500} sx={{
              objectFit: 'cover',
              borderRadius: '6px'
            }} />
              </Box>
            </Link> : null}
          {filteredAsset?.type === 'Video' ? <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
              <ReactPlayer light={<img src={filteredAsset.cover || ''} alt="Thumbnail" style={{
            width: '100%',
            height: '100%',
            borderRadius: '6px'
          }} />} url={filteredAsset.uri} controls={true} width="100%" height={isSmScreen ? 300 : 500} />
            </Box> : null}
          {filteredAsset?.type === 'Audio' ? <Box sx={{
          paddingTop: '1.5rem',
          height: '3.5rem',
          width: '100%'
        }}>
              <ReactPlayer url={filteredAsset.uri} controls={true} width="100%" height="100%" />
            </Box> : null}
        </Box>
      </Box>
    </Link>;
};