// ** React & MUI Imports
import { useEffect, useState } from 'react';
import { IconButton, SxProps, Typography } from '@mui/material';

// ** Hooks
import { useLens } from '@/context/lens';

// ** Components
import { IconThumbUp } from '@tabler/icons-react';
import { useSession } from '@/context/session';
import { MonitorError } from '@/utils/error';
type Props = {
  publicationId: string;
  initialLikes: number;
  isLiked: boolean;
  hideLabel?: boolean;
  sxButton?: SxProps;
};
export const LikeLensPost = ({
  publicationId,
  initialLikes,
  isLiked,
  hideLabel = false,
  sxButton
}: Props) => {
  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(isLiked);
  const [refresh, setRefresh] = useState(false);
  const {
    user
  } = useSession();
  const {
    like,
    unlike,
    getPostById
  } = useLens();
  useEffect(() => {
    if (user.profileId && refresh) {
      getPost();
      setRefresh(false);
    }
  }, [refresh, user.profileId]);
  const getPost = async () => {
    try {
      const lensPost = await getPostById(publicationId);
      if (!lensPost) throw new Error('Invalid lens post');
      if ('stats' in lensPost && 'operations' in lensPost) {
        const {
          stats,
          operations
        } = lensPost;
        setLiked(Boolean(operations.hasUpvoted));
        setLikes(stats.upvotes);
      } else {
        throw new Error('Unexpected lensPost structure:');
      }
    } catch (error) {
      console.log(`Error fetching post on challengeDetails:`, error);
      throw new MonitorError(error, {
        tags: {
          component: 'LikeLensPost',
          section: 'Get post',
          publicationId
        }
      });
    }
  };
  const handleLike = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (!user.address || !user.profileId) return;
      if (liked) {
        await unlike(publicationId);
      } else {
        await like(publicationId);
      }
      setRefresh(true);
    } catch (error: any) {
      console.log(`Error handling likes ${error}`);
    }
  };
  return <>
      <IconButton onClick={handleLike} color={liked ? 'primary' : 'inherit'} sx={sxButton} data-sentry-element="IconButton" data-sentry-source-file="LikeLensPost.tsx">
        <IconThumbUp size="18" data-sentry-element="IconThumbUp" data-sentry-source-file="LikeLensPost.tsx" />
      </IconButton>
      <Typography variant="body2" color={liked ? 'primary' : 'inherit'} data-sentry-element="Typography" data-sentry-source-file="LikeLensPost.tsx">
        {`${likes} ${hideLabel ? '' : 'Likes'}`}
      </Typography>
    </>;
};