import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CategoryType } from '@/types/custom';
import { useCategoryFilter } from '@/context/categoryFilter';
import { useSession } from '@/context/session';
import { LoginWallet } from '../ctaButtons/wallet/LoginWallet';
import { SignInBtn } from '../ctaButtons/wallet/SignInWallet';
type CategoriesDropdownFilterProps = {
  categories: CategoryType[];
};
const CategoriesDropdownFilter = ({
  categories
}: CategoriesDropdownFilterProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [categoriesIdsToApply, setCategoriesIdsToApply] = useState<number[]>([]);
  const [applyInProgress, setApplyInProgress] = useState<boolean>(false);
  const [applied, setApplied] = useState<boolean>(false);
  const {
    user,
    loggedIn,
    connectedNotLogged
  } = useSession();
  const {
    selectedCategories,
    handleStoreCategories,
    handleRemoveCategories
  } = useCategoryFilter();
  const selectedCategoriesIds = selectedCategories.map(c => c.id);
  const showCategories = loggedIn && user.profileId;
  const isDifferentCategories = selectedCategoriesIds.length !== categoriesIdsToApply.length || selectedCategoriesIds.some(id => !categoriesIdsToApply.includes(id)) || categoriesIdsToApply.some(id => !selectedCategoriesIds.includes(id));
  useEffect(() => {
    if (expanded) {
      setCategoriesIdsToApply(selectedCategoriesIds);
      setApplied(false);
    }
  }, [expanded]);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const handleCategoriesIdsToApply = (category: CategoryType) => {
    if (categoriesIdsToApply.includes(category.id)) {
      setCategoriesIdsToApply(categoriesIdsToApply.filter(id => id !== category.id));
    } else {
      setCategoriesIdsToApply([...categoriesIdsToApply, category.id]);
    }
  };
  const handleApplyCategories = async () => {
    setApplyInProgress(true);
    try {
      const categoriesIdsToRemove: number[] = [];
      for (const id of selectedCategoriesIds) {
        if (!categoriesIdsToApply.includes(id)) {
          categoriesIdsToRemove.push(id);
        }
      }
      const categoriesIdsToStore = [...categoriesIdsToApply].filter(id => !selectedCategoriesIds.includes(id));
      if (categoriesIdsToRemove.length > 0) {
        await handleRemoveCategories(categoriesIdsToRemove);
      }
      if (categoriesIdsToStore.length > 0) {
        const categoriesToStore = categories.filter(c => categoriesIdsToStore.includes(c.id));
        await handleStoreCategories(categoriesToStore);
      }
    } catch (error) {
      console.error('Error applying categories:', error);
    } finally {
      setApplyInProgress(false);
    }
  };
  const categoriesApplied = applied ? selectedCategoriesIds : categoriesIdsToApply;
  return <Box mb="1em" data-sentry-element="Box" data-sentry-component="CategoriesDropdownFilter" data-sentry-source-file="CategoriesDropdownFilter.tsx">
      <Accordion expanded={expanded} onChange={handleToggle} data-sentry-element="Accordion" data-sentry-source-file="CategoriesDropdownFilter.tsx">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
        backgroundColor: theme => theme.palette.primary.light,
        borderRadius: '0.5em'
      }} data-sentry-element="AccordionSummary" data-sentry-source-file="CategoriesDropdownFilter.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="CategoriesDropdownFilter.tsx">
            {expanded ? 'Select categories' : 'Personalize content'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="CategoriesDropdownFilter.tsx">
          {showCategories ? <Box display="flex" flexDirection="column" gap={3}>
              <Box display="grid" flexWrap={{
            xs: 'wrap'
          }} gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)'
          }} gap={1}>
                {categories.map((category, index) => <Button key={`category-${category.id}-${index}`} variant={categoriesApplied.includes(category.id) ? 'contained' : 'outlined'} size="small" onClick={() => handleCategoriesIdsToApply(category)} sx={{
              lineHeight: 1.5
            }}>
                    {category.name}
                  </Button>)}
              </Box>
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="error" size="small" sx={{
              width: '10em'
            }} disabled={!isDifferentCategories || applyInProgress} onClick={handleApplyCategories}>
                  {applyInProgress ? 'Applying...' : 'Apply'}
                </Button>
              </Box>
            </Box> : <Box position="relative">
              <Box display="grid" flexWrap={{
            xs: 'wrap'
          }} gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)'
          }} gap={1} sx={{
            filter: 'blur(4px)',
            pointerEvents: 'none'
          }}>
                {categories.map((category, index) => <Button key={`category-${category.id}-${index}`} variant={'outlined'} size="small" sx={{
              lineHeight: 1.5
            }}>
                    {category.name}
                  </Button>)}
              </Box>
              <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1} sx={{
              zIndex: 1,
              backgroundColor: theme => theme.palette.primary.light,
              padding: '1rem',
              borderRadius: '0.5rem'
            }}>
                  <Typography variant="body1" fontWeight={600}>
                    Should login to filter by categories
                  </Typography>
                  {connectedNotLogged ? <SignInBtn label="Login" size="medium" variant="outlined" color="primary" /> : <LoginWallet variant={'outlined'} color="primary" size={undefined} label="Login" />}
                </Box>
              </Box>
            </Box>}
        </AccordionDetails>
      </Accordion>
    </Box>;
};
export default CategoriesDropdownFilter;